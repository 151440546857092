<template>
    <div>
        <expert-layout>
            <done-record slot="record"></done-record>
            <undone-list slot="list"></undone-list>
            <undone-detail slot="detail"></undone-detail>
        </expert-layout>
    </div>
</template>
<script>
import ExpertLayout from "./layout";
import DoneRecord from "./done-record";
import UndoneList from "./undone-list";
import UndoneDetail from "./undone-detail";
export default {
    name: "expert_analysis",
    data() {
        return {};
    },
    components: {
        ExpertLayout,
        DoneRecord,
        UndoneList,
        UndoneDetail
    }
};
</script>