<template>
    <div class="undone-detail-base">
        <div class="undone-detail-container2" v-if="ifFxpgShow">
            <UndoneDetail></UndoneDetail>
        </div>
        <div class="undone-detail-container" v-else>
            <div class="no-data" v-if="!selectData || !this.rwId">暂无数据</div>
            <div class="has-data" v-else>
                <div class="name-title" :title="detailData.RWMC || ''">{{detailData.RWMC || ''}}</div>
                <div class="detail-bottom scrollbar">
                    <div class="title">步骤一 现有问题及相关素材</div>
                    <div class="detail-content">
                        <label class="content-label">任务描述</label>
                        <p class="content-p">{{detailData.RWMS}}</p>
                        <label class="content-label">任务对象</label>
                        <p class="content-p">{{detailData.JCDXMC}}</p>
                        <div>
                            <label class="content-label">相关文件以及数据支持</label>
                            <span class="label-tip">【请参考相关文件以及相关数据进行评估】</span>
                        </div>
                        <div class="fileBorder scrollbar">
                            <div class="file-single" v-for="(item,index) in jcsjfile" :key="`${item.ID}-${index}`">
                                <span class="fileText">
                                    <a class="assess-a" :href="item.CCLJ" target="_blank">{{item.WDMC}}</a>
                                </span>
                                <img class="file-icon" src="@image/expert_consultation/download.svg" @click="download(item)">
                            </div>
                            <div class="file-line" v-if="jcsjfile.length>0 && otherfile.length>0"></div>
                            <div class="file-single" v-for="(item,index) in otherfile" :key="`${item.ID}-${index}`">
                                <span class="fileText">
                                    <a class="assess-a" :href="item.CCLJ" target="_blank">{{item.WDMC}}</a>
                                </span>
                                <img class="file-icon" src="@image/expert_consultation/download.svg" @click="download(item)">
                            </div>
                        </div>
                        <div class="zp-info">
                            <el-button class="toMonitData" type="success" plain size="mini" @click="handleToMonitDataClick">查看系统相关数据</el-button>
                            <span class="label-tip" style="marginTop: 9px">【若现有评估数据缺少，请联系指派人员提供所缺数据】</span>
                        </div>
                        <div class="zp-info">
                            <p class="zp-info-text">指派时间：{{detailData.RWSCSJ}}</p>
                            <p class="zp-info-text">指派人：{{detailData.ZPRXM}}</p>
                            <p class="zp-info-text">指派人联系方式：{{detailData.LXDH}}</p>
                        </div>
                    </div>
                    <div class="title">步骤二 您需提供的数据</div>
                    <div class="detail-content">
                        <div>
                            <label class="content-label">专家评估</label>
                            <span class="label-tip">【请填写评估结果简述并上传评估报告】</span>
                        </div>
                        <div class="zjpg">
                            <div class="zjpg-item">
                                <span class="content-p2">评估结果简述：</span>
                                <el-input class="zjpg-item-input" type="textarea" :rows="6" v-model="pgjgjsValue" placeholder="请输入内容" size="mini" :disabled="!isEdit" v-if="isEdit"></el-input>
                                <div class="assess-p" v-else>{{pgjgjsText}}</div>
                            </div>
                            <div class="zjpg-item">
                                <span class="content-p2">评估报告：</span>
                                <div class="upload-content" v-if="isEdit">
                                    <ExpertUpload @successUpload="successUpload" :fileList="fileList"></ExpertUpload>
                                </div>
                                <div class="assess-p" v-else>
                                    <div v-for="(item,index) in pgbgList" :key="index">
                                        <a class="assess-a" :href="item.CCLJ" target="_blank">{{item.WDMC}}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="btnBorder">
                            <span class="btn-border" type="primary" v-if="isEdit" @click="pgjgSubmit">提交</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    selectData,
} from "@store/modules/mutation-type.js";
import { mapActions, mapGetters } from "vuex";
import ExpertUpload from "../components/expert-upload"
import UndoneDetail from '@views/working_desk/analysis_assess/undone-detail.vue'
export default {
    name: "undone-detail",
    data() {
        return {
            pgjgjsValue: "", //评估结果简述
            detailData: {
                RWMC: '', //任务名称
                RWMS: '', //任务描述
                JCDXMC: '', //监测对象名称
                RWSCSJ: '',	//任务生成时间(指派时间)
                ZPRXM: '', //指派人姓名
                LXDH: '', //联系电话
            },
            // saveDis: true, //提交按钮是否可见
            fjIds: '',
            rwId: '', //任务ID
            fileList: [],
            jcsjfile: [], //监测数据汇总文件
            otherfile: [], //其他相关文件
            formData: {}, //已分析评估详情
            isEdit: true,
            pgjgjsText: '',
            pgbgList: [],
            ifFxpgShow: false,
        };
    },
    computed: {
        ...mapGetters({
            selectData,
        })
    },
    watch: {
        selectData() {
            this.getDetailData(this.selectData);
        },
    },
    components: {
        ExpertUpload,
        UndoneDetail
    },
    mounted() {
        // eslint-disable-next-line no-undef
        $(document).ready(function() {
            let tmp = document.getElementsByClassName("el-textarea__inner");
            if (tmp && tmp.length > 0 && tmp[0].className) {
                tmp.forEach(item => {
                    item.className = "el-textarea__inner scrollbar";
                })
            }
        });
    },
    methods: {
        ...mapActions([
            'GetExpertAnalysisTask', //获取待分析评估记录详情
            'SaveExpertAnalysis', //专家上传评估分析结果
            'GetWTExpertAnalysisList',
            'GetSUExpertAnalysisList',
            'GetDealProcessExpert', //获取专家评估结果信息
        ]),
        async getDetailData(val) {
            this.detailData = {};
            this.pgjgjsValue = '';
            this.fileList = [];
            this.jcsjfile = [];
            this.otherfile = [];

            if(this.selectData.JJCDID === '1'){
                this.ifFxpgShow = true;
            }else{
                this.ifFxpgShow = false;

                if(val){
                    if (this.selectData.pgZt === 'undone') {
                        this.rwId = val.ID;
                        this.isEdit = true;
                        // if (this.selectData.ID && this.selectData.PGDXLX !== '1') {
                        //     this.getJcdxListByRwid();
                        // }
                    } else {
                        this.rwId = val.PGRWID;
                        this.isEdit = false;
                        // if (this.selectData.PGRWID) {
                        //     this.getPgjgmxInfo();
                        // }
                        this.getAssessData();
                    }

                    if (this.rwId) {
                        let result = await this.GetExpertAnalysisTask({
                            rwid: this.rwId
                        });
                        if (result) {
                            if (result.rwEntity && result.rwEntity.length > 0) {
                                this.detailData = result.rwEntity[0];
                            }
                            if (result.file && result.file.length > 0) {
                                this.jcsjfile = result.file;
                            }
                            if (result.otherfile && result.otherfile.length > 0) {
                                this.otherfile = result.otherfile;
                            }
                        }
                    }
                }
            }
        },
        async pgjgSubmit() {
            //保存数据
            let result = await this.SaveExpertAnalysis({
                rwid: this.rwId, //任务ID
                pgggjs: this.pgjgjsValue, //评估结果简述
                fjIds: this.fjIds, //附件ID(逗号分隔)
            })
            if (result.IsSuccess) {

                this.$message({
                    message: '提交成功',
                    type: 'success'
                });
                this.pgjgjsValue = '';
                this.fileList = [];
                this.GetWTExpertAnalysisList({
                    currentPage: 1,
                    pageSize: 3,
                    cjrid: localStorage.userId,
                    // cjrid: '78d1e990-9c1e-459a-8817-7958ae9a6059',
                });
                this.GetSUExpertAnalysisList({
                    currentPage: 1,
                    pageSize: 8,
                    cjrid: localStorage.userId,
                    // cjrid: '78d1e990-9c1e-459a-8817-7958ae9a6059',
                })
            } else {
                this.$message({
                    message: '提交失败',
                    type: 'error'
                });
            }
        },
        download(file) {
            console.log("file:", file.CCLJ);
            window.open(file.CCLJ);
        },
        handleToMonitDataClick() {
            let routeUrl = this.$router.resolve({
                path: "/monitoringdata",
                // query: {}
            });
            window.open(routeUrl.href, '_blank');
        },
        successUpload(val) {
            this.fjIds = val;
        },
        //获取专家评估结果信息
        async getAssessData() {
            this.pgjgjsText = '';
            this.pgbgList = {};
            if (this.selectData && this.rwId) {
                let result = await this.GetDealProcessExpert({
                    zjrwid: this.rwId
                });
                if (result && result.length > 0) {
                    this.pgjgjsText = result[0].Zjjy;
                    this.pgbgList = result[0].PGFJ;
                }
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.undone-detail-base{
    width: 100%;
    height: 100%;
    .undone-detail-container2{
        width: 100%;
        height: 100%;
        .undone-detail-container{
            border-left: none;
        }
    }
    .undone-detail-container {
        width: 100%;
        height: 100%;
        // border-right: 2px solid #2a3987;
        .no-data {
            text-align: center;
            padding-top: 25%;
            font-size: 20px;
        }
        .has-data {
            width: 100%;
            height: 100%;
        }
        .name-title {
            width: 100%;
            height: 40px;
            line-height: 40px;
            padding: 0 20px;
            text-align: center;
            font-size: 16px;
            font-weight: bold;
            background: #182987;
            color: #fff;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .detail-bottom {
            width: 100%;
            height: calc(100% - 40px);
            overflow-x: hidden;
            overflow-y: auto;
        }

        .detail-content {
            width: 100%;
            margin: 20px 0;
            padding: 20px;
            background: #fff;
        }

        .title {
            padding: 20px 0 0 20px;
            font-size: 16px;
            color: #2a3987;
            font-weight: bold;
        }

        .content-label {
            width: 160px;
            text-align: right;
            font-size: 15px;
            font-weight: bold;
        }

        .content-p {
            margin: 5px 0 15px 180px;
            font-size: 14px;
            word-break: break-all;
        }

        .zjpg {
            width: calc(100% - 0px);
            margin-top: 20px;
        }

        .zjpg-item {
            display: flex;
            flex-flow: row nowrap;
            margin-top: 10px;
        }
        .content-p2 {
            width: 150px;
            margin: 5px 0 15px 170px;
            font-size: 14px;
        }
        .zjpg-item .el-input {
            width: calc(100% - 140px);
        }

        .item1 {
            display: inline-flex;
        }

        .btnBorder {
            height: 30px;
            margin-top: 20px;
            margin-left: calc(50% - 30px);
        }
        .label-tip {
            color: #a1a1a1;
            font-size: 13px;
            font-weight: normal;
            margin-left: 5px;
        }

        .fileBorder {
            width: 100%;
            margin-top: 10px;
            padding: 0px 0 10px 180px;
        }
        .file-single {
            padding: 6px 0;
        }
        .file-line {
            width: 100%;
            height: 0px;
            border-top: 1px solid #ccc;
            margin: 10px 0;
            margin-left: -40px;
        }
        .fileText {
            color: #169bd5;
            text-decoration: underline;
            cursor: pointer;
            font-size: 14px;
        }
        .file-icon {
            margin-left: 10px;
            cursor: pointer;
        }
        .zp-info {
            display: flex;
            flex-flow: row nowrap;
            margin-left: 180px;
            padding: 10px 0;
        }
        .zp-info-text {
            margin-right: 50px;
            min-width: 60px;
        }
        .toMonitData.el-button--success.is-plain:focus,
        .toMonitData.el-button--success.is-plain:hover,
        .toMonitData.el-button--success.is-plain:active {
            color: #67C23A;
            background: #f0f9eb;
            border-color: #c2e7b0;
        }
        .upload-content {
            width: 100%;
        }
        .assess-a {
            color: #00f;
            cursor: pointer;
        }
        .btn-border {
            border-color: #1989fa;
            font-size: 14px;
            cursor: pointer;
            background-color: #1989fa;
            padding: 5px 16px;
            border-radius: 4px;
            color: #fff;
        }
        .assess-p {
            width: calc(100% - 100px);
            line-height: 29px;
            .assess-a {
                color: #00f;
                cursor: pointer;
            }
        }
    }
}
</style>

