<template>
    <div class="undone-list-taskList">
        <p class="taskTitle">待分析评估列表</p>
        <p class="num">{{undoneTotal}}项</p>
        <el-pagination class="commonGridPage" small @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize" layout="prev, pager, next" :total="undoneTotal" :pager-count="5" :hide-on-single-page="true">
        </el-pagination>
        <div class="task-content" v-if="waitTaskVis">
            <div :class="[item.ID==selectedId?'listDivSelect':'listDiv']" v-for="(item,index) in undoneData" :key="index" :index="`${index}`" @click="clickHandler(item)">
                <div class="taskfirst">
                    <img src="@image/expert_consultation/envelope.png" class="listimg">
                    <p class="tasklistName">{{item.PGBGMC}}</p>
                    <div class="task-type">{{item.JJCD}}</div>
                </div>
                <div class="task-info">
                    <p class="task-info-text">指派时间：{{item.RWSCSJ ? item.RWSCSJ.substr(0,10) : ''}}</p>
                    <p class="task-info-text">指派人：{{item.ZPRXM}}</p>
                    <p class="task-info-text">指派人联系方式：{{item.ZPRLXDH}}</p>
                </div>
                <div class="item-line" v-show="item.ID==selectedId"></div>
                <div class="click-arrow" v-show="item.ID==selectedId"></div>
            </div>
        </div>
        <div class="notask" v-else>
            <img src="@image/expert_consultation/notask.png" class="notaskimg">
            <p class="notaskFont">无待分析评估</p>
        </div>
    </div>
</template>
<script>
import { undoneData, undoneTotal, SET_SELECTDATA, selectData } from "@store/modules/mutation-type.js";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
    name: "undone-list",
    data() {
        return {
            waitTaskVis: false,
            selectedId: "",
            currentPage: 1, //当前页
            pageSize: 3, //每页条数
        };
    },
    computed: {
        ...mapGetters({
            undoneData,
            undoneTotal,
            selectData
        })
    },
    watch: {
        undoneData() {
            if (!this.undoneData || this.undoneData.length == 0) {
                this.waitTaskVis = false;
                this.clickHandler({});
            } else {
                this.waitTaskVis = true;
                this.clickHandler(this.undoneData[0]);
            }
        },
        selectData(){
            if(this.selectData && this.selectData.pgZt === 'done'){
                this.selectedId = '';
            }
        },
    },
    created() {
        this.getDoneData();
    },
    methods: {
        ...mapActions([
            'GetWTExpertAnalysisList'
        ]),
        ...mapMutations({
            SET_SELECTDATA
        }),
        clickHandler(item) {
            if(!item) return;
            let obj = {
                pgZt: 'undone',
            }
            Object.assign(obj, item);
            this.selectedId = obj.ID;
            this.SET_SELECTDATA(obj);

            // this.selectedId = item.ID;
            // this.SET_SELECTDATA(item);
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getDoneData();
        },
        getDoneData() {
            this.GetWTExpertAnalysisList({
                pageindex: this.currentPage,
                pagesize: this.pageSize,
                cjrid: localStorage.userId,
                // cjrid: '78d1e990-9c1e-459a-8817-7958ae9a6059',
            });
        }
    },
};
</script>
<style lang="scss" scoped>
.undone-list-taskList {
    position: relative;
    width: 100%;
    height: 100%;

    .taskTitle {
        height: 25px;
        font-size: 16px;
        color: #333;
    }
    .task-content {
        width: 100%;
        height: calc(100% - 30px);
        margin-top: 2px;
    }
    .item-line{
        position: absolute;
        top: 0px;
        left: 0px;
        width: 6px;
        height: 100%;
        background: #2a3987;
    }
    .click-arrow {
        position: absolute;
        top: calc(50% - 14px);
        left: 0px;
        width: 0px;
        height: 0px;
        border-width: 14px 16px;
        border-style: solid;
        border-color: transparent transparent transparent #2a3987;
    }
    .notask {
        position: absolute;
        left: calc(50% - 160px);
        top: calc(50% - 64px);
    }

    .notaskimg {
        display: inline;
    }

    .notaskFont {
        display: inline;
        margin-left: 15px;
        color: #A3A3A3;
    }

    .num {
        font-size: 13px;
        position: absolute;
        top: 4px;
        right: 0;
    }

    .listDiv {
        position: relative;
        width: 100%;
        height: calc(33.3% - 20px);
        margin: 10px 0;
        background: #fff;
        cursor: pointer;
    }

    .listDivSelect {
        position: relative;
        width: 100%;
        height: calc(33.3% - 20px);
        background: #e2e8db;
        margin: 10px 0;
        cursor: pointer;
    }
    .task-info{
        height: 50%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding: 10px 30px 0 30px;
        font-size: 13px;
        font-weight: bold;
    }
    .task-info-text{
        color: #aaa;
        font-weight: normal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .taskfirst {
        position: relative;
        height: 50%;
        width: 100%;
        padding: 0 30px;
    }

    .tasklistName {
        float: left;
        width: calc(100% - 120px);
        margin-left: 25px;
        margin-top: 17px;
        font-size: 16px;
        font-weight: bold;
        color: #2a3987;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .task-type{
        float: right;
        margin-top: 17px;
        font-size: 15px;
        font-weight: bold;
        color: #a66b36;
    }

    .listimg {
        margin-top: 20px;
        float: left;
        width: 20px;
        height: 16px;
    }
    .commonGridPage {
        position: absolute;
        top: 0;
        right: 40px;
    }
}
</style>

